import React, { useEffect, useState } from "react";
import "./../styles/sideBar.css"; // Import your CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCertificate,
  faClockRotateLeft,
  faDashboard,
  faGear,
  faHeart,
  faList,
  faSmile,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  getUser,
  getUserToken,
  removeToken,
  removeUser,
} from "./AppStorageKey";

const UserSidebar = (props: any) => {
  const { handleModal, showbuttons } = props;
  const [activeUser, setActiveUser] = useState<any>("");
  //console.log(activeUser , "user")
  // console.log(activeUser)

  const navigate = useNavigate();

  const checkUserLogin = async () => {
    try {
      let user = await getUser();
      user = JSON.parse(user || "");
      setActiveUser(user || ""); // H
    } catch (error) {
      console.error("Error checking user login:", error);
    }
  };

  useEffect(() => {
    checkUserLogin();
  }, []);

  return (
    <div className="user-sidebar ">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 bg-white px-0 rounded-4 ">
            <div className=" br-4">
              <div className="">
                <Link to={"/my-profile/user"} onClick={handleModal}>
                  <div
                    className="d-flex align-items-center"
                    style={{ paddingTop: "10px" }}
                  >
                    {(activeUser.account_type == 1 ||
                      activeUser.account_type == 2 ||
                      activeUser.account_type == 3) &&
                      (activeUser.user_profile?.user_profile_img_url ? (
                        <img
                          src={activeUser.user_profile?.user_profile_img_url}
                          className="imggg"
                          alt=""
                          width={80}
                          height={80}
                        />
                      ) : activeUser.user_profile?.gender === 1 ? (
                        <img
                          src={require("./../assets/images/user-m.png")}
                          className="imggg"
                          alt=""
                          width={80}
                          height={80}
                        />
                      ) : (
                        <img
                          src={require("./../assets/images/user-f.png")}
                          className="imggg"
                          alt=""
                          width={80}
                          height={80}
                        />
                      ))}
                    {activeUser.account_type == 4 &&
                      (activeUser.user_profile?.user_profile_img_url ? (
                        <img
                          src={activeUser.user_profile?.user_profile_img_url}
                          className="imggg"
                          alt=""
                          width={80}
                          height={80}
                        />
                      ) : activeUser.user_profile?.gender === 1 ? (
                        <img
                          src={require("./../assets/images/custom-mentor-m.png")}
                          className="imggg"
                          alt=""
                          width={80}
                          height={80}
                        />
                      ) : (
                        <img
                          src={require("./../assets/images/custom-mentor-f.png")}
                          className="imggg"
                          alt=""
                          width={80}
                          height={80}
                        />
                      ))}

                    <div className="user-detail mx-3">
                      <h6>{activeUser?.username}</h6>
                      <p>{activeUser?.email}</p>
                    </div>
                  </div>
                </Link>
                <div className="d-flex align-items-center justify-content-between my-4 mx-3">
                  <div className="d-flex align-items-center">
                    <img
                      src={require("./../assets/images/userdash/menu_rank.png")}
                      className="img-fluid"
                      alt=""
                      width={20}
                      height={20}
                    />
                    <div className="user-detail mx-2">
                      <p>10</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={require("./../assets/images/userdash/menu_points.png")}
                      className="img-fluid"
                      alt=""
                      width={20}
                      height={20}
                    />
                    <div className="user-detail mx-2">
                      <p>N/A</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={require("./../assets/images/userdash/menu_coins.png")}
                      className="img-fluid"
                      alt=""
                      width={20}
                      height={20}
                    />
                    <div className="user-detail mx-2">
                      <p>10</p>
                    </div>
                  </div>
                </div>
                {showbuttons && (
                  <div className="menu_list">
                    <div className="">
                      <button
                        className="complete-btn"
                        onClick={() => {
                          navigate("/my-profile/user");
                          handleModal();
                        }}
                      >
                        Complete Profile
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {activeUser.account_type === 4
                ? activeUser.is_mentor == "1" && (
                    <div className="menu_list">
                      <p>For Mentors</p>
                      <ul className="menus_sidebar">
                        <li className={"dashboard"}>
                          <Link
                            to={`/mentor/manage/dashboard/${activeUser.mentor_id}`}
                            onClick={handleModal}
                          >
                            <span>
                              <FontAwesomeIcon icon={faDashboard} />
                            </span>
                            Dashboard
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )
                : null}
              {activeUser.account_type == 3 && (
                <div className="menu_list">
                  <p>For Organizers</p>
                  <ul className="menus_sidebar">
                    <li className={"dashboard"}>
                      <Link
                        to={"/my-profile/manageListing"}
                        onClick={handleModal}
                      >
                        <span>
                          <FontAwesomeIcon icon={faList} />
                        </span>
                        Manage Listings
                      </Link>
                    </li>
                    <li className={"dashboard"}>
                      <Link
                        to={"/my-profile/plan&pricing"}
                        onClick={handleModal}
                      >
                        <span>
                          <FontAwesomeIcon icon={faTag} />
                        </span>
                        Plans & Billing
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
              <div className="menu_list">
                <p>For Users</p>
                <ul className="menus_sidebar">
                  <li className={"dashboard"}>
                    <Link
                      to={"/my-profile/userRegnApplication"}
                      onClick={handleModal}
                    >
                      <span>
                        <FontAwesomeIcon icon={faDashboard} />
                      </span>
                      Registrations/Applications
                    </Link>
                  </li>
                  <li className={"dashboard"}>
                    <Link
                      to={"/my-profile/recentlyViewed"}
                      onClick={handleModal}
                    >
                      <span>
                        <FontAwesomeIcon icon={faClockRotateLeft} />
                      </span>
                      Recently Viewed
                    </Link>
                  </li>
                  <li className={"dashboard"}>
                    <Link to={"/my-profile/watchList"} onClick={handleModal}>
                      <span>
                        <FontAwesomeIcon icon={faHeart} />
                      </span>
                      Watchlist
                    </Link>
                  </li>
                  <li className={"dashboard"}>
                    <Link to={"/my-profile/session"} onClick={handleModal}>
                      <span>
                        <FontAwesomeIcon icon={faSmile} />
                      </span>
                      Mentor Sessions
                    </Link>
                  </li>
                  <li className={"dashboard"}>
                    <Link to={"/my-profile/courses"} onClick={handleModal}>
                      <span>
                        <FontAwesomeIcon icon={faBook} />
                      </span>
                      Courses
                    </Link>
                  </li>
                  <li className={"dashboard"}>
                    <Link to={"/my-profile/certificate"} onClick={handleModal}>
                      <span>
                        <FontAwesomeIcon icon={faCertificate} />
                      </span>
                      Certificates
                    </Link>
                  </li>
                  {/* <li className={"dashboard"}>
                    <Link
                      to={"/my-profile/userRegnApplication"}
                      onClick={handleModal}
                    >
                      <span>
                        <FontAwesomeIcon icon={faGear} />
                      </span>
                      Settings
                    </Link>
                  </li> */}
                </ul>
              </div>
              {showbuttons && (
                <div className="menu_list">
                  <div className="">
                    <button
                      className="complete-btn"
                      onClick={() => {
                        removeToken();
                        removeUser();
                      }}
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSidebar;
