import { convertUTCtoIST } from "../utilities/CommonFunction";
import { removeToken, removeUser } from "../components/AppStorageKey";

interface ApiResponse {
  status: boolean;
  message: string;
  success: boolean;
  failure: string;
  data?: any;
  response?: any;
}

export default class ApiHandler {
  private _url: any;

  constructor() {
    this._url = process.env.REACT_APP_API_URL;
    //test api
    // this._url = "https://api.careers33.com/api";
    //production api
    //this._url = "https://admin.33careers.com/api";
  }

  getResult = (
    url: string,
    method: string = "GET",
    data: any = null,
    headers: any = null,
    success: (response: ApiResponse) => void = () => {},
    failed: (error: string) => void = () => {}
  ) => {
    let parameters: any = { method };
    if (data) {
      parameters.body = data;
    }
    if (headers) {
      parameters.headers = headers;
    }
    try {
      fetch(this._url + url, parameters)
        .then((response) => {
          if (response.status === 401 || response.status === 403) {
            removeToken();
            removeUser();
          }
          return response.json();
        })
        .then((response) => {
          success(response);
        })
        .catch((error) => {
          console.error(error);
          failed(error.message);
        });
    } catch (error: any) {
      console.error(error);
      failed(error.message);
    }
  };

  //---------*********** Auth API List ***********----------//

  register = (
    values: any,
    selectedImage: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: any) => void = () => {}
  ) => {
    let formData = new FormData();
    formData.append("account_type", values.role);
    // formData.append("first_name", values.firstName);
    // formData.append("last_name", values.lastName);
    formData.append("username", values.userName);
    formData.append("email", values.email);
    formData.append("country_code", "+91");
    formData.append("phone_number", values.phone);
    formData.append("password", values.password);
    formData.append("c_password", values.confirmPassword);
    formData.append("term_aggred", values.agree);
    formData.append("company_logo", values.logo);
    formData.append("company_name", values.companyName);

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    this.getResult(
      "/auth/signup",
      "POST",
      formData,
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response);
        } else if (response.success === true) {
          success(response.message, response);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  saveUser = (
    values: any,
    token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: any) => void = () => {}
  ) => {
    let body = JSON.stringify({
      gender: values.gender,
      organisation_id: values.organisation.value,
      location: values.city,
    });

    //console.log(body)

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    this.getResult(
      "/user/profile/create",
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response);
        } else if (response.success === true) {
          success(response.message, response);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  verifyOTP = (
    values: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    let body = JSON.stringify({
      email: values.email,
      otp: values.otp,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    this.getResult(
      "/auth/verify-email",
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  login = (
    email: string,
    pass: string,
    device_token: string,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    let body = JSON.stringify({
      email: email,
      password: pass,
      device_token: device_token,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    this.getResult(
      "/auth/login",
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  forgotPassword = (
    email: string,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    let body = JSON.stringify({
      email: email,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    this.getResult(
      "/auth/recover-password",
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  resetPassword = (
    token: string,
    email: string,
    password: string,
    confirmPassword: string,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    this.getResult(
      "/auth/reset-password?token=" +
        token +
        "&email=" +
        email +
        "&password=" +
        password +
        "&password_confirmation=" +
        confirmPassword,
      "POST",
      "",
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getUser = (
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/user",
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };
  //---------*********** Learn API List ***********----------//

  getAllCategories = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    this.getResult(
      "/categories",
      "GET",
      null,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getCourseList = (
    access_token: string,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/course-list",
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getCourseDetail = (
    token: any,
    courseId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    this.getResult(
      "/courses/" + courseId,
      "GET",
      "",
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data?.course);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getPackageDetail = (
    token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    this.getResult(
      "/package?type=1&price=1",
      "GET",
      "",
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getCourseContent = (
    courseId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-content-details/" + courseId}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  //---------*********** Video discussion API List ***********----------//
  createDiscussion = (
    courseID: any,
    level: any,
    parentId: any,
    comment: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify({
      course_content_detail_id: courseID,
      level: level,
      parent_id: parentId,
      discussion: comment,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-content-details/create-disscussion"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getCourseDiscussionList = (
    videoId: any,
    courseId: any,
    level: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${
        "/list-disscussion?course_content_detail_id=" +
        videoId +
        "&parent_id=" +
        courseId +
        "&level=" +
        level
      }`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  deleteCourseDiscussion = (
    commentId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-detail-disscussion/" + commentId}`,
      "DELETE",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  courseVideoComplete = (
    videoId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/complete-course/" + videoId}`,
      "POST",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getCertificate = (
    courseID: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/generate-certificates/" + courseID}`,
      "POST",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getAllCertificatenbyID = (
    id: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/certificates?course_id=" + id}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getAllCertificates = (
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/certificates"}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  enrollCourse = (
    courseId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/enroll/" + courseId}`,
      "POST",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  //---------*********** Course Rating API List ***********----------//
  createCourseRating = (
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify({
      course_id: 4,
      rating: "4",
      review: "Hello This is good product. And now i am enjoy.",
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-ratings"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getCourseRating = (
    courseId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-ratings/" + courseId}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getAllTimeZone = (
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/availability/timezone"}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getCourseVideoRating = (
    courseId: any,
    rating: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-ratings?course_id=" + courseId + "&rating=" + rating}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  //---------*********** Course Note's API List ***********----------//
  createNotes = (
    formData: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify(formData);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-detail/create-notes"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  updateNote = (
    noteId: any,
    formData: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = formData;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-detail/notes/" + noteId}`,
      "PUT",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  deleteNote = (
    noteId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-detail/notes/" + noteId}`,
      "DELETE",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getNotesList = (
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-detail/notes-list"}`,
      "POST",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  //---------*********** MentorShip Api List ***********----------//
  postMentorImage = (
    image: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify({
      image: "",
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/image"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  uploadMentorImage = (
    formValues: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const formData = new FormData();
    formData.append("image", formValues);

    const headers = {
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/image"}`,
      "POST",
      formData,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response);
        } else {
          failure("Something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createMentor = (
    formValues: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const {
      topics,
      image_id,
      skills,
      domain,
      bio,
      firstName,
      lastName,
      profileLink,
      gender,
      mobileCode,
      phoneNumber,
      organization,
      industry,
      role,
      experience,
      heading,
      languages,
      youtubeLink,
      linkedinLink,
      facebookLink,
      instagramLink,
    } = formValues;
    let languagesArray = languages.map((item: any) => item.value);

    let topicsArray = topics.map((item: any) => item.value);
    let skillsArray = skills.map((item: any) => item.value);

    const body = JSON.stringify({
      username: profileLink,
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      mobileCode: mobileCode,
      phone: phoneNumber,
      organisation_id: organization.value,
      industry_id: industry.value,
      current_role: role,
      experience: experience,
      short_description: heading,
      bio: bio,
      language_id: languagesArray,
      linkedin: linkedinLink,
      facebook: facebookLink,
      youtube: youtubeLink,
      instagram: instagramLink,
      image_id: image_id,
      domain_id: domain.value,
      topic_id: topicsArray,
      skill_id: skillsArray,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/create"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  updateMentor = (
    formValues: any,
    access_token: any,
    mentorId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const {
      firstName,
      lastName,
      image_id,
      profileLink,
      bio,
      gender,
      mobileCode,
      phoneNumber,
      organization,
      industry,
      role,
      experience,
      heading,
      languages,
      youtubeLink,
      linkedinLink,
      facebookLink,
      instagramLink,
    } = formValues;
    let languagesArray = languages && languages.map((item: any) => item.value);

    const body = JSON.stringify({
      username: profileLink,
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      phone: phoneNumber,
      organisation_id: organization.value,
      industry_id: industry.value,
      current_role: role,
      experience: experience,
      short_description: heading,
      bio: bio,
      language_id: languagesArray,
      linkedin: linkedinLink,
      facebook: facebookLink,
      youtube: youtubeLink,
      instagram: instagramLink,
      image_id: image_id,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/update/" + mentorId}`,
      "PUT",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getMentorList = (
    success = (message: any, data: any) => {},
    failure = (error: any) => {}
  ) => {
    this.getResult(
      "/mentor/list?page=1",
      "GET",
      "",
      null,
      (response) => {
        if (response.success === false) {
          failure(response);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getRandomMentorList = (
    success = (message: any, data: any) => {},
    failure = (error: any) => {}
  ) => {
    this.getResult(
      "/display-random-mentors",
      "GET",
      "",
      null,
      (response) => {
        if (response.success === false) {
          failure(response);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getFilteredMentorList = (
    filterBy: any,
    success = (message: any, data: any) => {},
    failure = (error: any) => {}
  ) => {
    const { organization, language, topics, skill } = filterBy;
    const org = organization && "organisation_id=" + organization;
    const lng = language && "&language_id[]=" + language;
    const t = topics && "&topic_id[]=" + topics;
    const skl = skill && "&skill_id[]=" + skill;

    this.getResult(
      `${"/mentor/list?" + org + lng + t}`,
      "GET",
      "",
      null,
      (response) => {
        if (response.success === false) {
          failure(response);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getMentorDetail = (
    mentorId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/detail/" + mentorId}`,
      "GET",
      null,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getMentorServiceList = (
    mentorId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/mentor/service/list/" + mentorId}`,
      "GET",
      "",
      null, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getMentorServiceDetail = (
    sessionId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    // this.getResult(`${'/mentor/booking/detail/' + mentorId + '/' + sessionId}`, "GET", '', null,  // Change "POST" to "GET"
    this.getResult(
      `${"/mentor/service/detail/" + sessionId}`,
      "GET",
      "",
      null, // Change "POST" to "GET"

      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getMentorBookingList = (
    mentorId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/booking/list/" + mentorId}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getBookingSlots = (
    access_token: any,
    mentorId: any,
    sessionId: any,
    date: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    const body = JSON.stringify({
      service_id: sessionId,
      mentor_id: mentorId,
      booking_date: date,
    });

    this.getResult(
      `${"/mentor/availability/list"}`,
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  //------ Availablity List -------//
  postAvailability = (
    access_token: any,
    formData: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify(formData);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    // console.log('formDataformData', formData)
    this.getResult(
      `${"/mentor/availability/create"}`,
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        console.log("errorerrorerror", error);
        failure(error);
      }
    );
  };

  getAvailabilityList = (
    access_token: any,
    mentorId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/availability/list/" + mentorId}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  bookSlot = (
    access_token: any,
    formdata: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    const isDate = convertUTCtoIST(formdata.date);

    const body = JSON.stringify({
      mentor_id: formdata.mentorId,
      service_id: formdata.sessionId,
      booking_status: 1,
      booking_type: 1,
      orderCreationId: formdata.order_id,
      payment_id: formdata.razorpay_payment_id,
      order_id: formdata.razorpay_order_id,
      razorpay_signature: formdata.razorpay_signature,
      booking_date: isDate,
      booking_start_time: formdata.bookingStartTime,
      booking_end_time: formdata.bookingEndTime,
    });

    this.getResult(
      `${"/mentor/booking/create"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getQualificationList = (
    mentorId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    this.getResult(
      `${"/mentor/education/qualification/list"}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  updateMentorDomain = (
    access_token: any,
    mentorId: any,
    formData: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    let domainArray = formData.domain.value;
    let topicsArray =
      formData.topics && formData.topics.map((item: any) => item.value);
    let skillsArray =
      formData.skills && formData.skills.map((item: any) => item.value);
    const body = JSON.stringify({
      domain_id: domainArray,
      topic_id: topicsArray,
      skill_id: skillsArray,
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    console.log(body);

    this.getResult(
      `${"/mentor/domain-update/" + mentorId}`,
      "PUT",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  addEducationDetails = (
    access_token: any,
    mentorId: any,
    formData: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify(formData);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/education/create/" + mentorId}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  addWorkExp = (
    access_token: any,
    mentorId: any,
    formData: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify({
      designation: formData.designation,
      sector: formData.sector,
      employment_type: formData.employment_type,
      organisation_id: formData.organisation.value,
      working_on: "false",
      from_year: formData.from_year,
      to_year: formData.to_year,
      country: formData.country,
      state: formData.state,
      city: formData.city,
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/work_experience/create/" + mentorId}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  addPaymentDetails = (
    access_token: any,
    mentorId: any,
    formData: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify(formData);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/payment_detail/create/" + mentorId}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        // console.log(response);
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getMentorPaymentDetail = (
    access_token: any,
    mentorId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    this.getResult(
      `${"/mentor/payment_detail/list/" + mentorId}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getMentorPaymentList = (
    mentorId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    this.getResult(
      `${"/mentor/payment_detail/payments/list/" + mentorId}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getMentorFeedBackList = (
    mentorId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    this.getResult(
      `${"/mentor/ratings/" + mentorId}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createMentorSevice = (
    access_token: any,
    mentorId: any,
    formData: any,
    success: (message: any, data: ApiResponse) => void = () => {},
    failure: (error: any) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/service/create/" + mentorId}`,
      "POST",
      JSON.stringify(formData),
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getMentorService = (
    mentorId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/service/detail/" + mentorId}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  updateMentorService = (
    serviceId: any,
    formData: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: any) => void = () => {}
  ) => {
    const body = JSON.stringify(formData);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/service/update/" + serviceId}`,
      "PUT",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  deleteMentorService = (
    serviceId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/service/" + serviceId}`,
      "DELETE",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  deActivateMentorService = (
    serviceId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify({
      status: 0,
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/service/inactive/" + serviceId}`,
      "PUT",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  activateMentorService = (
    serviceId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify({
      status: 10,
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/service/" + serviceId}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  mentorServiceList = (
    mentorId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/service/list/" + mentorId}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  mentorShipDomain = (
    courseId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify({
      mentorship_id: 3,
      domain_id: 1,
      topic_id: [1, 2],
      skill_id: [1],
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/save/1" + courseId}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getMentorSkillList = (
    courseId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/skill/list" + courseId}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getMentorTopicList = (
    topicId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/topic/list/" + topicId}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getMentorDomainList = (
    courseId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/mentor/domain/list"}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  // *********--------- careers api --------********** //
  getCareersList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/career/list"}`,
      "GET",
      "",
      null, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getCareersJobDetail = (
    jobId: any,
    token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    this.getResult(
      `${"/career/detail/" + jobId}`,
      "GET",
      null,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createJobDiscussion = (
    formData: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify(formData);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-detail/create-notes"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  delJobDiscussion = (
    noteId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-detail/notes/" + noteId}`,
      "DELETE",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getJobDiscList = (
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-detail/notes-list"}`,
      "POST",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createJobFeedBack = (
    formData: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify(formData);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/job-rating/create"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createMentorFeedBack = (
    formData: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify(formData);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/service-ratings"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getJobFeedback = (
    id: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    // const headers = {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //    // "Authorization": 'Bearer ' + access_token
    // };

    this.getResult(
      `${"/job-rating/list?job_id=" + id}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  // *********--------- careers api --------********** //
  getAllJobsList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/opportunity/list?status=10"}`,
      "GET",
      "",
      null, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getJobsList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/opportunity/list?opportunity_type=1&status=10"}`,
      "GET",
      "",
      null, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getOppList = (
    userId: any,
    page: any,
    oppType: any,
    search: any,
    status: any,
    expired: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${
        "/opportunity/list?user_id=" +
        userId +
        "&page=" +
        page +
        "&opportunity_type=" +
        oppType +
        "&opportunity_title=" +
        search +
        "&status=" +
        status +
        "&is_active=" +
        expired
      }`,
      "GET",
      "",
      null, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  // getOppListByCat = (oppType: any, userId: any, success: (message: string, data: ApiResponse) => void = () => { }, failure: (error: string) => void = () => { }) => {
  //     this.getResult(`${'/opportunity/list?user_id='+ userId + '&opportunity_type=' + oppType}`, "GET", '', null,  // Change "POST" to "GET"
  //         (response: ApiResponse) => {
  //             if (response.success === false) {
  //                 failure(response.message);
  //             } else if (response.success === true) {
  //                 success(response.message, response.data);
  //             } else {
  //                 failure("something went wrong");
  //             }
  //         },
  //         (error) => {
  //             failure(error);
  //         }
  //     );
  // };

  getIntershipsList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/opportunity/list?opportunity_type=2&status=10"}`,
      "GET",
      "",
      null, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getJobsListByCategory = (
    oppType: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/opportunity/list?opportunity_type=" + oppType}`,
      "GET",
      "",
      null, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getJobDetail = (
    jobId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/opportunity/about/" + jobId}`,
      "GET",
      "",
      null, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  addJobFeedBack = (
    formData: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify(formData);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/course-detail/create-notes"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getRegistrationList = (
    jobId: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Authorization: "Bearer " + access_token,
    };
    this.getResult(
      `${"/list/" + jobId}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  // *********--------- other api --------********** //

  getPartnersList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/partners"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getAllPackage = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      "/package?type=2",
      "GET",
      "",
      null, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getAllPackageID = (
    id: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      "/package?type=2&id=" + id,
      "GET",
      "",
      null, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  purchasePlan = (
    access_token: string,
    formData: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify(formData);

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/buy-package",
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  purchaseProPlan = (
    access_token: string,
    data: any,
    formData: any,
    taxAmount: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const { amount, package_id, transaction_id } = data;
    const {
      OrganisationName,
      GstNo,
      Address1,
      Address2,
      City,
      State,
      Country,
      postalCode,
    } = formData;

    const body = JSON.stringify({
      plan_package_id: package_id,
      transaction_id: transaction_id,
      tax_amount: taxAmount,
      amount: amount,
      remarks: "This is testing remarks....",
      status: "10",
      company_name: OrganisationName.label,
      gst: GstNo,
      address_line_1: Address1,
      address_line_2: Address2,
      city: City,
      state: State,
      postal_code: postalCode,
      country: Country,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/buy-plan",
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  //--------------------------------------------------
  getFaqPage = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/faq"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getPrivacyPage = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/page"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getTermsPage = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/page"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getIndustryList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/industry/list"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getOrganizationList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/organization/type"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };
  getAllOrganizationList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/organization/list"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getDomainList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/mentor/domain/list"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getLanguageList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/language"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getTopicList = (
    domainId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/mentor/topic/list/" + domainId}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getSkillsList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/mentor/skill/list"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getAllCourseList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/collage-courses"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getCourseSpecializationList = (
    filter: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const special = filter.type;
    this.getResult(
      `${"/collage-course-specialization?type=" + special}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getAllSpecializationList = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    //const special = filter.type
    this.getResult(
      `${"/collage-course-specialization"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getHobbiesList = (
    token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    this.getResult(
      `${"/user/hobby/list"}`,
      "GET",
      "",
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createOrder = (
    access_token: any,
    currency: any,
    amount: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify({
      currency: currency,
      amount: amount,
      notes: {},
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/razorpay/create-order"}`,
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  //  career job profile api

  jobFromSumbit = async (
    formData: any,
    access_token: any,
    id: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const {
      firstName,
      lastName,
      gender,
      organization,
      experienceYear,
      countryOfOrigin,
      resume,
      passout,
      designation,
      other,
    } = formData;

    let fd = new FormData();

    fd.append("first_name", firstName);
    fd.append("last_name", lastName);
    fd.append("gender", gender);
    fd.append("organization_id", organization.value);
    fd.append("user_type", designation);
    fd.append("specialization", "0");
    if (passout !== undefined) {
      fd.append("passing_out_year", passout);
    } else {
      fd.append("passing_out_year", "");
    }
    fd.append("course_duration", "2");
    fd.append("work_experience", experienceYear);
    //  fd.append('class', "2")
    // fd.append('others_type', other)
    if (other !== undefined) {
      fd.append("others_type", other);
    } else {
      fd.append("others_type", "");
    }
    fd.append("location", countryOfOrigin);
    fd.append("resume", resume);
    //   fd.append('status',' 10')
    fd.append("job_id", id);

    const headers = {
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/applicaint/create",
      "POST",
      fd,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  //////////////////////////////////////////job opportunity host apis//////////////////////////////////////////////////////////////

  getOpportunityType = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/opportunity-type"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getSubOpportunityType = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/sub-opportunity-type?opportunity_id=3"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getCategories = (
    id: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/host-category?opportunity_id=" + id}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getCity = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/organization/city_info?country_id=101"}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  jobPostForm = async (
    formData: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const {
      Opportunitytype,
      opportunityTitle,
      organizationName,
      websiteURL,
      description,
      skills,
      categories,
      minSalary,
      maxSalary,
      startDate,
      endDate,
      jobTiming,
      city,
      participationType,
      subOpportunity,
      modeOfEvent,
      stipend,
      min_participation,
      max_participation,
      collectResume,
      location,
      logo,
      numberOfApplicationsAllowed,
      salaryType,
      cityInput,
      state,
      country,
      Variable,
      maxExperience,
      minExperience,
      NoOfOpenings,
      workingDays,
      workPlaceType,
    } = formData;
    const categoryValues = categories
      ? categories.map((category: any) => category.value)
      : [];
    const skillsValue = skills ? skills.map((skill: any) => skill.value) : [];
    const cityValue = city ? city.map((city: any) => city.value) : [];

    let fd = new FormData();

    // basic details
    fd.append("logo", logo);
    fd.append("opportunity_type_id", Opportunitytype.value);
    if (subOpportunity) {
      fd.append("opportunity_sub_type_id", subOpportunity.value);
    } else {
      fd.append("opportunity_sub_type_id", "");
    }

    fd.append("organization_id", organizationName.value);
    fd.append("opportunity_title", opportunityTitle);
    if (websiteURL !== undefined) {
      fd.append("website_url", websiteURL);
    } else {
      fd.append("website_url", "");
    }

    skillsValue.forEach((value: any) => {
      fd.append("skill_id[]", value);
    });

    categoryValues.forEach((value: any) => {
      fd.append("category_id[]", value);
    });
    fd.append("description", description);

    //job details (Application Details)
    fd.append("start_date", startDate);
    fd.append("end_date", endDate);
    if (numberOfApplicationsAllowed !== undefined) {
      fd.append("limit_applications", numberOfApplicationsAllowed);
    } else {
      fd.append("limit_applications", "");
    }

    if (participationType) {
      fd.append("participation_type", participationType);
    } else {
      fd.append("participation_type", "1");
    }

    if (salaryType !== undefined) {
      fd.append("salary_type", salaryType);
    } else {
      fd.append("salary_type", "");
    }

    if (country !== undefined) {
      fd.append("country", country);
    } else {
      fd.append("country", "");
    }

    if (state !== undefined) {
      fd.append("state", state);
    } else {
      fd.append("state", "");
    }

    if (cityInput !== undefined) {
      fd.append("city", cityInput);
    } else {
      fd.append("city", "");
    }

    if (location !== undefined) {
      fd.append("location", location);
    } else {
      fd.append("location", "");
    }

    if (minSalary !== undefined) {
      fd.append("min_salary", minSalary);
    } else {
      fd.append("min_salary", "");
    }

    if (maxSalary !== undefined) {
      fd.append("max_salary", maxSalary);
    } else {
      fd.append("max_salary", "");
    }

    if (Variable !== undefined) {
      fd.append("variable", Variable);
    } else {
      fd.append("variable", "");
    }

    if (minExperience !== undefined) {
      fd.append("min_experience", minExperience);
    } else {
      fd.append("min_experience", "");
    }

    if (maxExperience !== undefined) {
      fd.append("max_experience", maxExperience);
    } else {
      fd.append("max_experience", "");
    }

    if (NoOfOpenings !== undefined) {
      fd.append("opening_no", NoOfOpenings);
    } else {
      fd.append("opening_no", "");
    }

    if (workingDays !== undefined) {
      fd.append("work_day", workingDays);
    } else {
      fd.append("work_day", "");
    }

    if (jobTiming !== undefined) {
      fd.append("job_time", jobTiming);
    } else {
      fd.append("job_time", "");
    }

    fd.append("job_type", workPlaceType);

    cityValue.forEach((value: any) => {
      fd.append("city_id[]", value);
    });

    // "event_type": ,  //if it give 0 so we pass country , state, city,location
    // "state": ,
    //  "country": ,
    fd.append("visibility", "1");

    fd.append("contact", "1");
    if (modeOfEvent) {
      fd.append("event_type", modeOfEvent);
    } else {
      fd.append("event_type", "1");
    }

    if (collectResume) {
      fd.append("collect_resume", collectResume);
    } else {
      fd.append("collect_resume", "1");
    }

    if (stipend) {
      fd.append("is_paid", stipend);
    } else {
      fd.append("is_paid", "1");
    }

    fd.append("is_salary", "1");
    //participation

    if (min_participation !== undefined) {
      fd.append("min", min_participation);
    } else {
      fd.append("min", "");
    }

    if (max_participation !== undefined) {
      fd.append("max", max_participation);
    } else {
      fd.append("max", "");
    }

    fd.append("currency", "INR");
    fd.append("status", "10");

    const headers = {
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/opportunity/create",
      "POST",
      fd,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  jobUpdateForm = async (
    formData: any,
    access_token: any,
    jobId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const {
      Opportunitytype,
      opportunityTitle,
      organizationName,
      websiteURL,
      description,
      skills,
      categories,
      minSalary,
      maxSalary,
      startDate,
      endDate,
      jobTiming,
      city,
      participationType,
      subOpportunityType,
      modeOfEvent,
      stipend,
      min_participation,
      max_participation,
      collectResume,
      location,
      logo,
      numberOfApplicationsAllowed,
      salaryType,
      Variable,
      maxExperience,
      minExperience,
      NoOfOpenings,
      workingDays,
      workPlaceType,
    } = formData;

    const categoryValues = categories
      ? categories.map((category: any) => category.value)
      : [];
    const skillsValue = skills ? skills.map((skill: any) => skill.value) : [];
    const cityValue = city ? city.map((city: any) => city.value) : [];

    const body = JSON.stringify({
      opportunity_type_id: Opportunitytype ? Opportunitytype.value : "",
      opportunity_sub_type_id: subOpportunityType
        ? subOpportunityType.value
        : "",
      visibility: 1,
      opportunity_title: opportunityTitle,
      organization_id: organizationName.value,
      website_url: websiteURL,
      event_type: modeOfEvent, //if it give 0 so we pass country , state, city,location
      state: "Himachal Pradesh",
      country: "India",
      city: "Palampur",
      location: "Patti",
      description: description,
      skill_id: skillsValue,
      category_id: categoryValues,
      participation_type: participationType, //if you select 1 so you can't pass the min max values
      min: min_participation,
      max: max_participation,
      start_date: startDate,
      end_date: endDate,
      contact: 1,
      limit_applications: numberOfApplicationsAllowed,
      collect_resume: collectResume,
      is_paid: 1,
      salary_type: salaryType,
      is_salary: 2,
      city_id: cityValue,
      currency: "INR",
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/opportunity/edit/" + jobId,
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  postImportantDates = async (
    formData: any,
    access_token: any,
    jobId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify({
      name: formData.name,
      email: formData.email,
      country_code: 91,
      phone: formData.contactNo,
    });
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/create/" + jobId,
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        console.log(response, 564456456);
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  updateImportantDates = async (
    formData: any,
    access_token: any,
    jobId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify({
      name: formData.name,
      email: formData.email,
      country_code: 91,
      phone: formData.contactNo,
    });
    const headers = {
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/update/" + jobId,
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        console.log(response, 564456456);
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getJobPost = (
    page: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/opportunity/list?opportunity_type=1&status=10&page=" + page}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getIntershipPost = (
    page: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/opportunity/list?opportunity_type=2&status=10&page=" + page}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getGeneralCaseCompetitionsPost = (
    page: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/opportunity/list?opportunity_type=3&status=10&page=" + page}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };
  getWebinarsPost = (
    page: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/opportunity/list?opportunity_type=4&status=10&page=" + page}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getQuizzesPost = (
    page: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/opportunity/list?opportunity_type=5&status=10&page=" + page}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getjobDetail = (
    access_token: any,
    jobId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/opportunity/about/" + jobId}`,
      "GET",
      null,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getjobApplyDetail = (
    access_token: any,
    jobId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/host-opportunity-applicant-detail/" + jobId}`,
      "GET",
      null,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getFilteredJobList = (
    filterBy: any,
    success = (message: any, data: any) => {},
    failure = (error: any) => {}
  ) => {
    const { organization, skill, type, search, isActive } = filterBy;
    const org = organization && "&organization_id=" + organization;
    //const lng = language && '&language_id[]=' + language;
    // const t = topics && '&topic_id[]=' + topics
    const skl = skill && "&skill_id=" + skill;
    const job_type = type && "&job_type=" + type;
    const title = search && "&opportunity_title=" + search;
    const active = isActive && "&is_active=" + isActive;

    // console.log('/opportunity/list?opportunity_type=1&status=10' + org + job_type + skl + title);

    this.getResult(
      `${
        "/opportunity/list?opportunity_type=1&status=10" +
        org +
        job_type +
        skl +
        title +
        active
      }`,
      "GET",
      "",
      null,
      (response) => {
        if (response.success === false) {
          failure(response);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getFilteredIntershipsList = (
    filterBy: any,
    success = (message: any, data: any) => {},
    failure = (error: any) => {}
  ) => {
    const { organization, skill, type, search, isActive } = filterBy;
    const org = organization && "&organization_id=" + organization;
    //const lng = language && '&language_id[]=' + language;
    // const t = topics && '&topic_id[]=' + topics
    const skl = skill && "&skill_id=" + skill;
    const job_type = type && "&job_type=" + type;
    const title = search && "&opportunity_title=" + search;
    const active = isActive && "&is_active=" + isActive;

    this.getResult(
      `${
        "/opportunity/list?opportunity_type=2&status=10" +
        org +
        job_type +
        skl +
        title +
        active
      }`,
      "GET",
      "",
      null,
      (response) => {
        if (response.success === false) {
          failure(response);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getFilteredgetGeneralCompPostList = (
    filterBy: any,
    success = (message: any, data: any) => {},
    failure = (error: any) => {}
  ) => {
    const { organization, skill, type, search, isActive } = filterBy;
    const org = organization && "&organization_id=" + organization;
    //const lng = language && '&language_id[]=' + language;
    // const t = topics && '&topic_id[]=' + topics
    const skl = skill && "&skill_id=" + skill;
    const job_type = type && "&job_type=" + type;
    const title = search && "&opportunity_title=" + search;
    const active = isActive && "&is_active=" + isActive;

    this.getResult(
      `${
        "/opportunity/list?opportunity_type=3&status=10" +
        org +
        job_type +
        skl +
        title +
        active
      }`,
      "GET",
      "",
      null,
      (response) => {
        if (response.success === false) {
          failure(response);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getFilteredWebinarPostList = (
    filterBy: any,
    success = (message: any, data: any) => {},
    failure = (error: any) => {}
  ) => {
    const { organization, skill, type, search, isActive } = filterBy;
    const org = organization && "&organization_id=" + organization;
    //const lng = language && '&language_id[]=' + language;
    // const t = topics && '&topic_id[]=' + topics
    const skl = skill && "&skill_id=" + skill;
    const job_type = type && "&job_type=" + type;
    const title = search && "&opportunity_title=" + search;
    const active = isActive && "&is_active=" + isActive;

    this.getResult(
      `${
        "/opportunity/list?opportunity_type=4&status=10" +
        org +
        job_type +
        skl +
        title +
        active
      }`,
      "GET",
      "",
      null,
      (response) => {
        if (response.success === false) {
          failure(response);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getFilteredQuizzesPostList = (
    filterBy: any,
    success = (message: any, data: any) => {},
    failure = (error: any) => {}
  ) => {
    const { organization, skill, type, search, isActive } = filterBy;
    const org = organization && "&organization_id=" + organization;
    //const lng = language && '&language_id[]=' + language;
    // const t = topics && '&topic_id[]=' + topics
    const skl = skill && "&skill_id=" + skill;
    const job_type = type && "&job_type=" + type;
    const title = search && "&opportunity_title=" + search;
    const active = isActive && "&is_active=" + isActive;

    this.getResult(
      `${
        "/opportunity/list?opportunity_type=5&status=10" +
        org +
        job_type +
        skl +
        title +
        active
      }`,
      "GET",
      "",
      null,
      (response) => {
        if (response.success === false) {
          failure(response);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  jobApplySumit = async (
    formData: any,
    access_token: any,
    id: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const {
      firstName,
      lastName,
      gender,
      organization,
      experienceYear,
      countryOfOrigin,
      resume,
      passout,
      designation,
      others_type,
      Specializations,
      course,
      abled,
      agree,
    } = formData;

    //const courseID = course.id
    //console.log(courseID , "id course")

    let fd = new FormData();

    fd.append("first_name", firstName);
    fd.append("last_name", lastName);
    fd.append("gender", gender);
    fd.append("organization_id", organization.value);
    fd.append("user_type", designation);

    if (course.value !== undefined) {
      fd.append("course_id", course.value);
    } else {
      fd.append("course_id", "");
    }
    //fd.append('course_id', course.value)
    if (Specializations.value !== undefined) {
      fd.append("specialization", Specializations.value);
    } else {
      fd.append("specialization", "");
    }

    // fd.append('specialization', Specializations.value)
    if (passout !== undefined) {
      fd.append("passing_out_year", passout);
    } else {
      fd.append("passing_out_year", "");
    }
    fd.append("course_duration", "2");
    fd.append("work_experience", experienceYear);
    //  fd.append('class', "2")
    // fd.append('others_type', other)
    if (others_type !== undefined) {
      fd.append("others_type", others_type);
    } else {
      fd.append("others_type", "");
    }
    fd.append("is_differently_abled", abled);
    fd.append("location", countryOfOrigin);
    fd.append("resume", resume);
    fd.append("status", " 1");
    fd.append("job_id", id);
    if (agree == true) {
      fd.append("is_agree", "1");
    }

    const headers = {
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `/apply/` + id,
      "POST",
      fd,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  jobApplyUpdate = async (
    formData: any,
    access_token: any,
    id: any,
    userId: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const {
      firstName,
      lastName,
      gender,
      organization,
      experienceYear,
      countryOfOrigin,
      resume,
      passout,
      designation,
      others_type,
      Specializations,
      course,
      abled,
      agree,
    } = formData;

    //const courseID = course.id
    //console.log(courseID , "id course")

    let fd = new FormData();

    fd.append("first_name", firstName);
    fd.append("last_name", lastName);
    fd.append("gender", gender);
    fd.append("organization_id", organization.value);
    fd.append("user_type", designation);

    if (course.value !== undefined) {
      fd.append("course_id", course.value);
    } else {
      fd.append("course_id", "");
    }
    //fd.append('course_id', course.value)
    if (Specializations.value !== undefined) {
      fd.append("specialization", Specializations.value);
    } else {
      fd.append("specialization", "");
    }

    // fd.append('specialization', Specializations.value)
    if (passout !== undefined) {
      fd.append("passing_out_year", passout);
    } else {
      fd.append("passing_out_year", "");
    }
    fd.append("course_duration", "2");
    fd.append("work_experience", experienceYear);
    //  fd.append('class', "2")
    // fd.append('others_type', other)
    if (others_type !== undefined) {
      fd.append("others_type", others_type);
    } else {
      fd.append("others_type", "");
    }
    fd.append("is_differently_abled", abled);
    fd.append("location", countryOfOrigin);
    fd.append("resume", resume);
    fd.append("status", " 1");
    fd.append("job_id", id);
    if (agree == true) {
      fd.append("is_agree", "1");
    }

    const headers = {
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `/host-opportunity-application/update/` + id + "/" + userId,
      "POST",
      fd,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createOrganization = async (
    formData: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const { org, orgType, image } = formData;

    //const courseID = course.id
    //console.log(courseID , "id course")

    let fd = new FormData();

    fd.append("name", org);
    fd.append("organization_type_id", orgType.value);
    fd.append("organization_logo", image);
    fd.append("status", "10");

    const headers = {};

    this.getResult(
      `/organization/create`,
      "POST",
      fd,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  // USER DASHBOARD API
  createUserAbout = (
    formData: any,
    id: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const { role, interests, about } = formData;

    const body = JSON.stringify({
      purpose: role,
      interests: interests,
      about_me: about,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/profile/about/" + id}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createUserSkills = (
    formData: any,
    id: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const { skills } = formData;

    let skillsArray = skills.map((item: any) => item.value);
    // console.log(skillsArray)

    const body = JSON.stringify({
      skill_id: skillsArray,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/profile/skill/" + id}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createUserEducation = (
    formData: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const {
      qualification,
      specialization,
      organization,
      skills,
      description,
      degree,
      DegreeType,
      Stream,
      Board,
      startDate,
      endDate,
      Percentage,
      CGPA,
      rollNumber,
      lateralEntry,
      location,
    } = formData;

    let skillsArray = skills.map((item: any) => item.value);

    const body = JSON.stringify({
      qualification_id: qualification.value,
      degree: degree.value,
      degree_type: DegreeType.value,
      specialization_id: specialization.value,
      from_year: startDate,
      to_year: endDate,
      percentage: Percentage,
      cgpa: CGPA,
      organization_id: organization.value,
      roll_no: rollNumber,
      city: location,
      description: description,
      stream: Stream.label,
      board: Board,
      skill_id: skillsArray,
      //  "lateralEntry" : lateralEntry
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/profile/education"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  updateUserEducation = (
    formData: any,
    access_token: any,
    id: any,
    itemID: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const {
      qualification,
      specialization,
      organization,
      skills,
      description,
      degree,
      DegreeType,
      Stream,
      Board,
      startDate,
      endDate,
      Percentage,
      CGPA,
      rollNumber,
      lateralEntry,
      location,
    } = formData;

    let skillsArray = skills.map((item: any) => item.value);

    const body = JSON.stringify({
      qualification_id: qualification.value,
      degree: degree.value,
      degree_type: DegreeType.value,
      specialization_id: specialization.value,
      from_year: startDate,
      to_year: endDate,
      percentage: Percentage,
      cgpa: CGPA,
      organization_id: organization.value,
      roll_no: rollNumber,
      city: location,
      description: description,
      stream: Stream.label,
      board: Board,
      skill_id: skillsArray,
      //  "lateralEntry" : lateralEntry
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/profile/update_education/" + id + "/" + itemID}`,
      "PUT",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createUserHobbies = (
    formData: any,
    id: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const { skills } = formData;

    let skillsArray = skills.map((item: any) => item.value);
    console.log(skillsArray);

    const body = JSON.stringify({
      hobby_id: skillsArray,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/profile/add_hobby"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getUserSkills = (
    id: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/skills/list/" + id}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getQualificationType = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    this.getResult(
      `${"/mentor/education/qualification/list"}`,
      "GET",
      "",
      null, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createUserBasicDetails = (
    formData: any,
    id: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const {
      firstName,
      lastName,
      userName,
      email,
      gender,
      user_type,
      organisation,
      location,
      dob,
      c_add,
      c_landmark,
      c_pincode,
      c_city_id,
      p_add,
      p_landmark,
      p_pincode,
      p_city_id,
      mobile,
    } = formData;

    const body = JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      username: userName,
      gender: gender,
      user_type: user_type,
      organisation_id: organisation.value,
      location: location,
      dob: dob,
      c_add: c_add,
      c_landmark: c_landmark,
      c_pincode: c_pincode,
      c_city_id: c_city_id.value,
      p_add: p_add,
      p_landmark: p_landmark,
      p_pincode: p_pincode,
      p_city_id: p_city_id.value,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/profile/update/" + id}`,
      "PUT",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createUserWorkExp = (
    formData: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const {
      employment_type,
      designation,
      sector,
      from_year,
      to_year,
      organisation,
      city,
      description,
      skills,
    } = formData;

    let skillsArray = skills.map((item: any) => item.value);

    const body = JSON.stringify({
      employment_type: employment_type.value,
      designation: designation,
      sector: sector,
      from_year: from_year,
      to_year: to_year,
      organization_id: organisation.value,
      city: city,
      description: description,
      skill_id: skillsArray,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/profile/add_work_exp"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  updateUserWorkExp = (
    formData: any,
    access_token: any,
    id: any,
    itemID: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const {
      employment_type,
      designation,
      sector,
      from_year,
      to_year,
      organisation,
      city,
      description,
      skills,
    } = formData;

    let skillsArray = skills.map((item: any) => item.value);

    const body = JSON.stringify({
      employment_type: employment_type.value,
      designation: designation,
      sector: sector,
      from_year: from_year,
      to_year: to_year,
      organization_id: organisation.value,
      city: city,
      description: description,
      skill_id: skillsArray,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/profile/update_work_exp/" + id + "/" + itemID}`,
      "PUT",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createUserSocailLink = (
    formData: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const { linkedin, facebook, youtube, instagram, twitter, git } = formData;

    const body = JSON.stringify({
      linkedin: linkedin || "",
      facebook: facebook || "",
      youtube: youtube || "",
      instagram: instagram || "",
      twitter: twitter || "",
      git: git || "",
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/profile/add_social_links"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  updateUserSocailLink = (
    formData: any,
    access_token: any,
    id: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const { linkedin, facebook, youtube, instagram, twitter, git } = formData;

    const body = JSON.stringify({
      linkedin: linkedin || "",
      facebook: facebook || "",
      youtube: youtube || "",
      instagram: instagram || "",
      twitter: twitter || "",
      git: git || "",
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/profile/update_social_links/" + id}`,
      "PUT",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getUserWorkExperience = (
    id: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/work_exp/list/" + id}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };
  getUserEducation = (
    id: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/education/list/" + id}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getUserSocial = (
    id: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/social_link/list/" + id}`,
      "GET",
      "",
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createApplyJobFeedBack = (
    formData: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify(formData);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/host-opportunity-ratings"}`,
      "POST",
      body,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getApplyJobFeedback = (
    id: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    // const headers = {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //    // "Authorization": 'Bearer ' + access_token
    // };

    this.getResult(
      `${"/host-opportunity-ratings?host_opportunity_id=" + id}`,
      "GET",
      "",
      null,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getRecentlyView = (
    access_token: any,
    page: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/recent-view?page=" + page}`,
      "GET",
      null,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getuserCourse = (
    access_token: any,
    page: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/enroll-list?page=" + page}`,
      "GET",
      null,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  postWatchList = async (
    datas: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const { watchable_id, watchable_type } = datas;

    const body = JSON.stringify({
      watchable_id: watchable_id,
      watchable_type: watchable_type,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/watchlist/add",
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  updateWatchList = async (
    datas: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const { watchable_id, watchable_type } = datas;

    const body = JSON.stringify({
      watchable_id: watchable_id,
      watchable_type: watchable_type,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/watchlist/remove",
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getWatchLists = (
    access_token: any,
    filter: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/watchlist?watchable_type=" + filter}`,
      "GET",
      null,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getSessionList = (
    access_token: any,
    id: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/user/booking/list/" + id}`,
      "GET",
      null,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  postsubscribe = async (
    email: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify({
      email: email,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/subscribe",
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  hostOpportunityAction = (
    formData: any,
    access_token: any,
    application_id: any,
    oppId: any,
    userId: any,
    selectedFile: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const { subject, data, action } = formData;

    let fd = new FormData();
    // const body = JSON.stringify({
    //   host_opportunity_id: oppId,
    //   action: action,
    //   subject: subject,
    //   data: data,
    // });
    fd.append("host_opportunity_id", oppId);
    fd.append("action", action);
    fd.append("subject", subject);
    fd.append("data", data);
    fd.append("attachment", selectedFile);

    const headers = {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${"/host-application-action/" + application_id + "/" + userId}`,
      "POST",
      fd,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  createPrize = async (
    formFields: any[],
    id: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    //const { rank ,cashAmount ,otherDetails} = formFields
    const prizeBodies = formFields.map((prize) => ({
      rank: prize.rank,
      amount: prize.cashAmount,
      other_details: prize.otherDetails,
    }));

    const body = JSON.stringify({
      ranks: prizeBodies,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/create_prize/" + id,
      "POST",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  updatePrize = async (
    formFields: any[],
    id: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    //const { rank ,cashAmount ,otherDetails} = formFields
    const prizeBodies = formFields.map((prize) => ({
      rank: prize.rank,
      amount: prize.cashAmount,
      other_details: prize.otherDetails,
    }));

    const body = JSON.stringify({
      ranks: prizeBodies,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/update_prize/" + id,
      "PUT",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  //slider

  getSlider = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "Authorization": 'Bearer ' + access_token
    };

    this.getResult(
      `${"/slider"}`,
      "GET",
      null,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  getUserJobList = (
    page: any,
    oppType: any,
    search: any,
    status: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      `${
        "/host-opportunity-applied?action=" +
        status +
        "&page=" +
        page +
        "&opportunity_type=" +
        oppType
      }`,
      "GET",
      null,
      headers, // Change "POST" to "GET"
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  applicantOffer = async (
    id: any,
    user_id: any,
    oppoId: any,
    offerStatus: any,
    comment: any,
    access_token: any,
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const body = JSON.stringify({
      condidate_action: offerStatus,
      comments: comment,
    });

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    this.getResult(
      "/host-opportunity-applicant-offer/" + id + "/" + user_id + "/" + oppoId,
      "PUT",
      body,
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };

  //our numbers

  getNumbers = (
    success: (message: string, data: ApiResponse) => void = () => {},
    failure: (error: string) => void = () => {}
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: "Bearer " + token,
    };

    this.getResult(
      "/our-numbers",
      "GET",
      "",
      headers,
      (response: ApiResponse) => {
        if (response.success === false) {
          failure(response.message);
        } else if (response.success === true) {
          success(response.message, response.data);
        } else {
          failure("something went wrong");
        }
      },
      (error) => {
        failure(error);
      }
    );
  };
}
