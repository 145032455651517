import React from "react";
import { Headers } from "./Header";
import { Outlet, useLocation } from "react-router-dom";
import { Footer } from "./Footer";

export const MainLayout = () => {
    const location = useLocation();
    // Check if the current route is login or register
    const isLoginOrRegister = location.pathname === "/login" || location.pathname === "/register" ||  location.pathname === "/forgot-password" || location.pathname == "*";

    return (
        <div>
            {!isLoginOrRegister && (<Headers />)}
            <Outlet />
            {!isLoginOrRegister && (<Footer />)}
        </div>
    );
};
