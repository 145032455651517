import React, { useRef, useState } from 'react'
import CustomInputComp from '../CustomInputComp'
import { modeOfEvent, opportunityType } from '../../utilities/app-const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faUpload } from '@fortawesome/free-solid-svg-icons';
import './JobPostStep.css';
import { Avatar, Button, IconButton } from '@mui/material'
import CustomSelectComp from '../CustomSelectComp';
import Select from 'react-select';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const JobPostStep1 = (props: any) => {
    const { error, value, handleChange, organisationList, setOrganisationList, formData, setFormData, subOpportunityType, opportunityTypes, opportunityType, skills, categories, disabled, nonDisabled  }= props;
  


    const [values, setValues] = useState('');

    //passout year dynamic getting
    const currentYear = new Date().getFullYear();
    const passout = [{ title: "All", value: "All" }];

    for (let i = 0; i < 4; i++) {
        const year = currentYear - i;
        passout.push({ title: String(year), value: String(year) });
    }



    const [selectedImage, setSelectedImage] = useState<string | null>(null);


    const [inputValue, setInputValue] = useState('');
    const [menuIsOpen, setMenuIsOpen] = useState(false);
  
    const handleInputChange = (newValue : any) => {
      setInputValue(newValue);
      setMenuIsOpen(newValue ? true : false);
    }

    const handleImageChange = (event: any) => {
        const file = event.target.files?.[0];
        if (!file) return;
        handleChange("logo", file);
        const reader = new FileReader();
        reader.onload = () => {
            const result = reader.result;
            if (typeof result === 'string') {
                setSelectedImage(result);
            }
        };
        reader.readAsDataURL(file);
    };


    return (
        <div className='profile-Form job-post-step'>
            <h5 className='mb-4'>Basic Details</h5>
            {/* <div className="image-uploader">
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                    id="upload-image"
                    disabled={disabled}
                />
                <label htmlFor="upload-image">
                    <div className="upload-box">
                        {selectedImage ? (
                            <>
                                <img src={selectedImage} alt="Uploaded" className="uploaded-image" />
                            </>
                        ) : (
                            <span>Click to upload Icon</span>
                        )}
                    </div>
                </label>
            </div> */}

            <CustomSelectComp
                label="Opportunity Type"
                name={'Opportunitytype'}
                id="Opportunitytype"
                required={true}
                value={value.Opportunitytype}
                options={opportunityType}
            //  error={error.organizationName}
                handleChange={handleChange}
                disabled={true}
            />

            {opportunityTypes == 3 &&
                <CustomSelectComp
                    label="Opportunity Sub-Type"
                    name={'subOpportunity'}
                    id="subOpportunity"
                    required={true}
                    value={value.subOpportunity}
                    options={subOpportunityType}
                    error={error.subOpportunity}
                    handleChange={handleChange}
                    disabled={disabled}
                />
            }

            <CustomInputComp
                type={"text"}
                label="Opportunity Title"
                id="opportunityTitle"
                name="opportunityTitle"
                required={true}
                value={value.opportunityTitle}
                handleChange={(e: any) => { handleChange('opportunityTitle', e.target.value) }}
                error={error.opportunityTitle}
                disabled={disabled}
            />

         <CustomSelectComp
              label="Enter your Organization"
              name={'organizationName'}
              id="organizationName"
              required={true}
              value={value.organizationName}
              options={organisationList}
              setOrganisationList={setOrganisationList}
              handleChange={handleChange}
              error={error.organizationName}
              disabled={true}
          />  

            {/* <CustomInputComp
                type="text"
                label="Website URL"
                id="websiteURL"
                name="websiteURL"
                value={value.website_url}
                handleChange={(e: any) => { handleChange('websiteURL', e.target.value) }}
                error={error.website_url}
                disabled={disabled}
            /> */}


            {(opportunityTypes == "3" || opportunityTypes == "4" || opportunityTypes == "5")
                &&
                <>
                <div className="form-group8 mb-4">
                    <label htmlFor="your-name" className="form-label mb-2"> Mode of Event<span className="red_star">*</span> </label>
                    {/* <div className="row form-group mb-4">
                        {modeOfEvent.map((modeOfEventOption, index) => (
                            <div className='col' style={{ position: "relative" }}>
                                <label key={index}
                                    htmlFor={`modeOfEvent-${index}`}
                                    className={`form-label gender-box  ${value.modeOfEvent && value.modeOfEvent === modeOfEventOption.title ? 'active' : ''}`}
                                    onClick={() => handleChange('modeOfEvent', modeOfEventOption.value)}
                                    aria-disabled={true}>
                                    <div className={'check_boxes'}>
                                        <span><FontAwesomeIcon className="fa-solid fa-user" icon={modeOfEventOption.icon} /></span>
                                    </div>
                                    <span className="checkbox_title">{modeOfEventOption.title}</span>
                                    {value.modeOfEvent && value.modeOfEvent === modeOfEventOption.value && (<div className="selected-check" >
                                        <FontAwesomeIcon icon={faCircleCheck} />
                                    </div>)
                                    }
                                </label>
                            </div>
                        ))}
                        {error.modeOfEvent && <div className='text-danger'>{error.modeOfEvent}</div>}
                    </div> */}
                    <div className="row form-group mb-4">
                    {modeOfEvent.map((modeOfEventOption, index) => (
            <div className='col' style={{ position: "relative" }}>
                <label key={index}
                    htmlFor={`modeOfEvent-${index}`}
                    className={`form-label gender-box
                        ${value.modeOfEvent === modeOfEventOption.value ? 'active' : ''}`}
                    onClick={() => {
                        if (!disabled) {
                            handleChange('modeOfEvent', modeOfEventOption.value);
                        }
                    }}
                    aria-disabled={true}>
                    <div className={'check_boxes'}>
                        <span><FontAwesomeIcon className="fa-solid fa-user" icon={modeOfEventOption.icon} /></span>
                    </div>
                    <span className="checkbox_title">{modeOfEventOption.title}</span>
                    {value.modeOfEvent === modeOfEventOption.value && (
                        <div className="selected-check" >
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </div>
                    )}
                </label>
            </div>
        ))}
    {error.modeOfEvent && <div className='text-danger'>{error.modeOfEvent}</div>}
</div>
                </div>

                {(value.modeOfEvent === "0" && 
             
                    <div>
                        <CustomInputComp
                            type="text"
                            label="Country"
                            id="country"
                            name="country"
                            required={true}
                             value={value.country}
                            handleChange={(e: any) => { handleChange('country', e.target.value) }}
                           error={error.country}
                        disabled={disabled}
                        />
                        <CustomInputComp
                            type="text"
                            label="State"
                            id="state"
                            name="state"
                            required={true}
                             value={value.state}
                            handleChange={(e: any) => { handleChange('state', e.target.value) }}
                          error={error.state}
                        disabled={disabled}
                        />
                        <CustomInputComp
                            type="text"
                            label="City"
                            id="city"
                            name="city"
                            required={true}
                             value={value.cityInput}
                            handleChange={(e: any) => { handleChange('cityInput', e.target.value) }}
                          error={error.cityInput}
                        disabled={disabled}
                        />
                        <CustomInputComp
                            type="text"
                            label="Location"
                            id="location"
                            name="location"
                            required={true}
                             value={value.location}
                            handleChange={(e: any) => { handleChange('location', e.target.value) }}
                           error={error.location}
                        disabled={disabled}
                        />
                        </div> 
                    )}
                    

                </>
                }

            <div className="form-group1 mb-4">
                <label htmlFor="your-name" className="form-label"> Categories <span className="red_star">*</span></label>
                {/* <p className="sub-label">Provide a list of yours (up to 10) , that mentees can use to discover you</p> */}
                <Select
                    value={value.categories}
                    onChange={(selectedOption) => setFormData((prevData: any) => ({ ...prevData, categories: selectedOption }))}
                    options={categories}
                    name="categories"
                    className={`${error.categories && 'is-invalid'}`}
                    isMulti={true}
                    isDisabled={disabled}
                />
                {error.categories && <div className="invalid-feedback">{error.categories}</div>}
            </div>

          <div className="form-group1 mb-4">
              <label htmlFor="your-name" className="form-label"> Skills to be Accessed <span className="red_star">*</span></label>
              {/* <p className="sub-label">Provide a list of yours (up to 10) , that mentees can use to discover you</p> */}
              <Select
                  value={value.skills}
                  onChange={(selectedOption) => setFormData((prevData: any) => ({ ...prevData, skills: selectedOption }))}
                  options={skills}
                  name="skills"
                  className={`${error.skills && 'is-invalid'}`}
                  isMulti={true}
                 menuIsOpen={menuIsOpen}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  isDisabled={disabled}
              />
               {error.skills && <div className="invalid-feedback">{error.skills}</div>}
          </div>
           



            <label>Job Description<span className="red_star">*</span></label>
            <ReactQuill
                theme="snow"
                // value={values} 
                // onChange={setValues}
                value={value.description}
                modules={{
                    toolbar: true, // Show the toolbar
                    clipboard: {
                      matchVisual: true, 
                    },
                  }}
                className={`${error.description && 'is-invalid'}`}
                onChange={(e) => { handleChange('description', e) }}
                readOnly={disabled}
            />
  
            {error.description && <div className="invalid-feedback">{error.description}</div>}

        </div>

    )
}

export default JobPostStep1