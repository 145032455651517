import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/custom.css";
import { MainLayout } from "./pages/MainLayout";
import React, { Suspense, useEffect } from "react";
import { AppRoutesConst } from "./utilities/app-const";
import TermsAndCondPage from "./pages/TermsAndCond";
import ScrollToTop from "./components/ScrollToTop";
import "react-toastify/dist/ReactToastify.css";
import JobPostForm from "./pages/JobsPage/post-jobs/jobPostForm/JobPostForm";

// public pages
const HomePage = React.lazy(() => import("./pages/HomePage"));
const LearnPage = React.lazy(() => import("./pages/LearnPage"));
const CourseDetail = React.lazy(() => import("./pages/LearnPage/CourseDetail"));
const CourseContent = React.lazy(
  () => import("./pages/LearnPage/CourseContent")
);
const MentorShipPage = React.lazy(() => import("./pages/MentorshipPage"));
const RegisterPage = React.lazy(() => import("./pages/RegisterPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const BecomeMentor = React.lazy(
  () => import("./pages/MentorPage/BecomeMentor")
);
const MentorForm = React.lazy(() => import("./pages/MentorPage/MentorForm"));
const FindAMentor = React.lazy(() => import("./pages/MentorPage/FindAMentor"));
const MentorDetailPage = React.lazy(
  () => import("./pages/MentorPage/MentorDetailPage")
);
const PracticePage = React.lazy(() => import("./pages/PracticePage"));
const PricingPage = React.lazy(() => import("./pages/PricingPage"));
const EmployersPage = React.lazy(() => import("./pages/EmployersPage"));
const Compete = React.lazy(() => import("./pages/CompetePage"));
const CertificationCourse = React.lazy(
  () => import("./pages/LearnPage/CertificationCourse")
);
const CrashCourse = React.lazy(() => import("./pages/LearnPage/CrashCourse"));

const Jobs = React.lazy(() => import("./pages/JobsPage"));
const JobOpportunity = React.lazy(
  () => import("./pages/JobsPage/post-jobs/JobOpportunity")
);
const JobForm = React.lazy(
  () => import("./pages/JobsPage/post-jobs/jobPostForm/JobPostForm")
);
const ExploreJobs = React.lazy(
  () => import("./pages/JobsPage/exploreJobs/ExploreJobs")
);
const Interships = React.lazy(
  () => import("./pages/JobsPage/exploreInterships/Interships")
);
const GeneralComp = React.lazy(
  () => import("./pages/JobsPage/generalComp/GeneralComp")
);
const WebinarsWorkshops = React.lazy(
  () => import("./pages/JobsPage/WebinarsWorkshops/WebinarsWorkshops")
);
const Quizzes = React.lazy(() => import("./pages/JobsPage/Quizzes/Quizzes"));
const JobApplyForm = React.lazy(
  () => import("./pages/JobsPage/JobApplyForm/JobApplyForm")
);

const Certificate = React.lazy(() => import("./pages/Certificate/Certificate"));

const JobPostDetail = React.lazy(
  () => import("./pages/JobsPage/JobPostDetail")
);
const OnlineQuizing = React.lazy(() => import("./pages/OnlineQuizingPage"));
const Partners = React.lazy(() => import("./pages/PartnersPage"));
const Billing = React.lazy(() => import("./pages/PricingPage/Billing/Index"));

 const Clients = React.lazy(() => import("./pages/ClientPage"));
const AboutUs = React.lazy(() => import("./pages/AboutUsPage"));
const FaqPage = React.lazy(() => import("./pages/FaqsPage"));
const PrivacyPolicyPage = React.lazy(() => import("./pages/PrivacyPolicyPage"));
const CareersPage = React.lazy(() => import("./pages/CareersPage"));
const JobDetail = React.lazy(() => import("./pages/CareersPage/JobDetail"));
const JobCreateForm = React.lazy(
  () => import("./pages/CareersPage/JobCreateForm")
);
const CareerApplyForm = React.lazy(
  () => import("./pages/CareersPage/CareerApplyForm")
);

// mentor pages
const BookMentor = React.lazy(
  () => import("./pages/MentorPage/MentorBookings")
);
const MentorBooked = React.lazy(
  () => import("./pages/MentorPage/MentorBooked")
);
const MentorMainDashboard = React.lazy(
  () => import("./dashboards/MentorDashBoard")
);
const MentAvailability = React.lazy(
  () => import("./dashboards/MentorDashBoard/MentAvailability")
);

// pricing screen
const ChoosePlan = React.lazy(() => import("./pages/PricingPage/ChoosePlan"));
const PricingPlans = React.lazy(
  () => import("./pages/PricingPage/PricingPlans")
);

// admin dashboards
const UserDashBoard = React.lazy(() => import("./dashboards/UserDashboard"));

// opportunity dashboards
const OppMainDash = React.lazy(() => import("./dashboards/OppDashBoard"));

// Auth Pages
function App() {

  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Routes>
        <Route element={<MainLayout />}>
          <Route
            index
            element={
              <Suspense>
                <HomePage />
              </Suspense>
            }
          />
          <Route path="*" element={<h1>not found</h1>} />
          <Route
            path={AppRoutesConst.login}
            element={
              <Suspense>
                <LoginPage />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.forgotPassword}
            element={
              <Suspense>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.resetPassword}
            element={
              <Suspense>
                <ResetPassword />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.register}
            element={
              <Suspense>
                <RegisterPage />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.learn}
            element={
              <Suspense>
                <LearnPage />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.courseDetail}
            element={
              <Suspense>
                <CourseDetail />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.courseContent}
            element={
              <Suspense>
                <CourseContent />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.Certificate}
            element={
              <Suspense>
                <Certificate />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.mentorship}
            element={
              <Suspense>
                <MentorShipPage />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.becomeMentor}
            element={
              <Suspense>
                <BecomeMentor />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.mentorForm}
            element={
              <Suspense>
                <MentorForm />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.findMentor}
            element={
              <Suspense>
                <FindAMentor />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.ManageMentor}
            element={
              <Suspense>
                <MentorMainDashboard />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.ManageMentor}
            element={
              <Suspense>
                <MentAvailability />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.practice}
            element={
              <Suspense>
                <PracticePage />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.pricingPage}
            element={
              <Suspense>
                <PricingPage />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.pricing}
            element={
              <Suspense>
                <PricingPlans />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.ChoosePlan}
            element={
              <Suspense>
                <ChoosePlan />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.Billing}
            element={
              <Suspense>
                <Billing />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.employers}
            element={
              <Suspense>
                <EmployersPage />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.compete}
            element={
              <Suspense>
                <Compete />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.OppMainDash}
            element={
              <Suspense>
                <OppMainDash />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.quiz}
            element={
              <Suspense>
                <OnlineQuizing />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.jobs}
            element={
              <Suspense>
                <Jobs />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.jobOpportunity}
            element={
              <Suspense>
                <JobOpportunity />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.jobPostForm}
            element={
              <Suspense>
                <JobPostForm />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.jobPostDetail}
            element={
              <Suspense>
                <JobPostDetail />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.exploreJobs}
            element={
              <Suspense>
                <ExploreJobs />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.interships}
            element={
              <Suspense>
                <Interships />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.GeneralComp}
            element={
              <Suspense>
                <GeneralComp />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.Quizzes}
            element={
              <Suspense>
                <Quizzes />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.WebinarsWorkshops}
            element={
              <Suspense>
                <WebinarsWorkshops />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.jobApplyForm}
            element={
              <Suspense>
                <JobApplyForm />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.MentorDetailPage}
            element={
              <Suspense>
                <MentorDetailPage />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.partners}
            element={
              <Suspense>
                <Partners />
              </Suspense>
            }
          />
           <Route path={AppRoutesConst.clients} element={<Suspense><Clients /></Suspense>} />
          <Route
            path={AppRoutesConst.aboutUs}
            element={
              <Suspense>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.faqs}
            element={
              <Suspense>
                <FaqPage />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.terms}
            element={
              <Suspense>
                <TermsAndCondPage />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.privacy}
            element={
              <Suspense>
                <PrivacyPolicyPage />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.CertificationCourses}
            element={
              <Suspense>
                <CertificationCourse />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.crashCourse}
            element={
              <Suspense>
                <CrashCourse />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.career}
            element={
              <Suspense>
                <CareersPage />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.jobDetail}
            element={
              <Suspense>
                <JobDetail />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.JobCreateForm}
            element={
              <Suspense>
                <JobCreateForm />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.CareerApplyForm}
            element={
              <Suspense>
                <CareerApplyForm />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.BookMentor}
            element={
              <Suspense>
                <BookMentor />
              </Suspense>
            }
          />
          <Route
            path={AppRoutesConst.MentorBooked}
            element={
              <Suspense>
                <MentorBooked />
              </Suspense>
            }
          />

          <Route
            path={AppRoutesConst.UserProfile}
            element={
              <Suspense>
                <UserDashBoard />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
