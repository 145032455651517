import React, { useEffect, useState } from "react";
import ApiHandler from "./../../model/ApiHandler";

interface TermsPage {
    description: string;
}

const TermsAndCondPage = () => {
    const api = new ApiHandler();
    const [data, setData] = useState<TermsPage[]>([]);

    const getPrivacyPolicy = async () => {
        api.getTermsPage((message: string, response: any) => {
            setData(response.pagelist);
        },
            (error: any) => {
                console.log(error);
            }
        );
    };

    useEffect(() => {
        getPrivacyPolicy();
    }, []);

    return (
        <main>
            <section className="banner bckkk condi">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="con h-100 d-flex align-items-center">
                                <div className="conn">
                                    <h3>Terms and<span> Conditions</span></h3>
                                    <p>Participate, Showcase Skills &amp; Gain CV Points through online &amp; offline
                                        opportunities of
                                        your interest &amp; make your mark!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="con d-flex justify-content-center">
                                <img src={require("./../../assets/images/ter.avif")} alt="" className="img-fluid" style={{ borderRadius: '30px', width: '46%' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="terms">
                <div className="container">
                    <div className="row">
                        <div className="con">
                            <div className="conn">
                                <div dangerouslySetInnerHTML={{ __html: data[0]?.description }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default TermsAndCondPage;