import { faBars, faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './../styles/header.css'
import { getUser, getUserToken, removeToken, removeUser } from "../components/AppStorageKey";
import { Avatar } from "@mui/material";
import RightModalComponent from "../components/RightModalComponent";
import UserSidebar from "../components/UserSideBar";
import NotificationSidebar from "../components/NotificationSidebar";


export const Headers = () => {
    const navigate = useNavigate()
    const [showHeader, setShowHeader] = useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState<string | null>(null);
    const [activeUser, setActiveUser] = useState<any>('');
    const [showSideBar, setShowSideBar] = useState<boolean | null>(false);

    const [showNotiBar , setShowNotiBar] = useState<boolean | null>();

    const checkUserLogin = async () => {
        try {
            const token = await getUserToken();
            let user = await getUser();
            user = JSON.parse(user || '')
          //  console.log(user)
            setIsUserLoggedIn(token);
            setActiveUser(user || ''); // H
        } catch (error) {
            console.error('Error checking user login:', error);
        }
    };

    const handleSideBar = () => {
        setShowSideBar(!showSideBar)
    }

    const handleNotiBar = () => {
        setShowNotiBar(!showNotiBar);
    }

    useEffect(() => {
        checkUserLogin();
    }, []);

    return (
        <header className="header dash-head">
            <nav className="navbar navbar-expand-lg navbar-light ">
                <div className="container">
                    <div className="navbar-collapse collapse show" id="navbarSupportedContent" style={{}}>
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">
                                    <img src={require("./../assets/images/logo-new.png")} className="img-fluid logo" alt="" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="">
                        <ul className="navbar-nav me-auto tab-hide mb-2 mb-lg-0 ">
                            <li className="nav-item tab-hide">
                                <Link className="nav-link" to="/learn">
                                    {/* <img src={require("./../assets/images/learn.png")} className="imggg" alt="" />  */}
                                Learn</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/practice"><img src={require("./../assets/images/practice.png")} className="imggg" alt="" />Practice</Link>
                            </li> */}
                            <li className="nav-item  tab-hide">
                                <Link className="nav-link" to="/mentorship">
                                    {/* <img src={require("./../assets/images/mentorship.png")} className="imggg" alt="" />  */}
                                    Mentorship</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/compete"><img src={require("./../assets/images/complete.png")} className="imggg" alt="" /> Compete</Link>
                            </li> */}
                            <li className="nav-item  tab-hide">
                                <Link className="nav-link" to="/jobs">
                                    {/* <img src={require("./../assets/images/jobs.png")} className="imggg" alt="" />  */}
                                    Jobs</Link>
                            </li>
                            
                            {/* notification icon */}
                            {/* <div style={{cursor : "pointer"}} onClick={() => setShowNotiBar(!showNotiBar)}>
                                <FontAwesomeIcon icon={faBell} />
                                </div> */}
                          

                            {activeUser.account_type === 3 && (
                                <li className="nav-item tab-hide">
                                    <button className="btn btndon" type="submit">For Business</button>
                                </li>
                            )}
        
                            <li className="nav-item user-img tab-hide">
                                 {/* {isUserLoggedIn == null  ?
                                    <button className="btn btn2 btndon" type="submit" onClick={() => { navigate('/login') }}>Login</button>
                                    :(
                                        (activeUser.account_type == 1 || 
                                            activeUser.account_type == 2  || 
                                             activeUser.account_type == 3) && (
                                                 activeUser.user_profile?.user_profile_img_url ?
                                                     <img src={activeUser.user_profile.user_profile_img_url} className="imggg" alt="" onClick={() => { setShowSideBar(!showSideBar) }} />
                                                     :
                                                     activeUser.user_profile?.gender === 1 ?
                                                         <img src={require("./../assets/images/user-m.png")} className="imggg" alt="" onClick={() => { setShowSideBar(!showSideBar) }} />
                                                         :
                                                         <img src={require("./../assets/images/user-f.png")} className="imggg" alt="" onClick={() => { setShowSideBar(!showSideBar) }} />
                                         ))  :(

                                         )
                                        
                                }  */}

                                {isUserLoggedIn == null ? (
                                    <>
                                    <button className="btn  btndon" onClick={() => {navigate('/register')}}>Register</button>
                                    <button className="btn btn2 btndon" type="submit" onClick={() => { navigate('/login') }}>Login</button>
                                    </>
                                ) : (
                                    activeUser.account_type == 4 ? (
                                        activeUser.user_profile?.user_profile_img_url ? (
                                            <img src={activeUser.user_profile.user_profile_img_url} className="imggg" alt="" onClick={() => { setShowSideBar(!showSideBar) }} style={{cursor : 'pointer'}}/>
                                        ) : (
                                            activeUser.user_profile?.gender === 1 ? (
                                                <img src={require("./../assets/images/custom-mentor-m.png")} className="imggg" alt="" onClick={() => { setShowSideBar(!showSideBar) }} style={{cursor : 'pointer'}}/>
                                            ) : (
                                                <img src={require("./../assets/images/custom-mentor-f.png")} className="imggg" alt="" onClick={() => { setShowSideBar(!showSideBar) }} style={{cursor : 'pointer'}} />
                                            )
                                        )
                                    ) : (
                                        (activeUser.account_type == 1 || activeUser.account_type == 2 || activeUser.account_type == 3) && (
                                            activeUser.user_profile?.user_profile_img_url ? (
                                                <img src={activeUser.user_profile.user_profile_img_url} className="imggg" alt="" onClick={() => { setShowSideBar(!showSideBar) }} style={{cursor : 'pointer'}}/>
                                            ) : (
                                                activeUser.user_profile?.gender === 1 ? (
                                                    <img src={require("./../assets/images/user-m.png")} className="imggg" alt="" onClick={() => { setShowSideBar(!showSideBar) }} style={{cursor : 'pointer'}}/>
                                                ) : (
                                                    <img src={require("./../assets/images/user-f.png")} className="imggg" alt="" onClick={() => { setShowSideBar(!showSideBar) }} style={{cursor : 'pointer'}}/>
                                                )
                                            )
                                        )
                                    )
                                )}

                               

                               
                                   
  
                            </li>
                            <li className="nav-item">
                                <a id="toggleBTN" className="navbar-brand" onClick={() => setShowHeader(!showHeader)}><FontAwesomeIcon icon={faBars} /></a>
                            </li>
                        </ul>
                    </div>
                    {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}

                </div>
            </nav>

            <section className={showHeader ? "mega megaShow" : "mega"}>
                <div className="container">
                    <div className="row">
                        <ul className="cls">
                            <li className="cl1">
                                <ul className="brr">
                                    {/* <li><a href="compete">
                                        <img src={require("./../assets/images/complete.png")} className="imggg" alt="" />
                                        Compete
                                    </a></li>
                                    <li><a href="jobs">
                                        <img src={require("./../assets/images/jobs.png")} className="imggg" alt="" />
                                        jobs
                                    </a></li> */}
                                    <li><a href="career">
                                        <img src={require("./../assets/images/career.png")} className="imggg" alt="" />
                                        careers
                                    </a></li>

                                    <li><a href="pricing">
                                        <img src={require("./../assets/images/pricing.png")} className="imggg" alt="" />
                                        Pricing
                                    </a></li>
                                    <li>
                                        <a href="privacy-policy">
                                            <img src={require("./../assets/images/privacy.png")} className="imggg" alt="" />
                                            privacy policy
                                        </a>
                                    </li>
                                    {/* <li><a href="employers">
                                        <img src={require("./../assets/images/employee.png")} className="imggg" alt="" />
                                        employers
                                    </a></li> */}
                                </ul>
                            </li>
                            <li className="cl2">
                                <ul className="brr">

                                    <li><a href="online-quizing">
                                        <img src={require("./../assets/images/online2.png")} className="imggg" alt="" />
                                        online Quizing
                                    </a></li>

                                    <li><a href="partner">
                                        <img src={require("./../assets/images/partner2.png")} className="imggg" alt="" />
                                        partners
                                    </a></li>
                                    <li><a href="clients">
                                        <img src={require("./../assets/images/client.png")} className="imggg" alt="" />
                                        client
                                    </a></li>
                                </ul>
                            </li>
                            <li className="cl3">
                                <ul className="brr">
                                    <li><a href="about-us">
                                        <img src={require("./../assets/images/about-us.png")} className="imggg" alt="" />
                                        about us
                                    </a></li>
                                    <li><a href="faqs">
                                        <img src={require("./../assets/images/faq.png")} className="imggg" alt="" />
                                        faq
                                    </a></li>
                                    <li>
                                        <a href="terms-and-conditions">
                                            <img src={require("./../assets/images/term.png")} className="imggg" alt="" />
                                            terms & Conditions
                                        </a>
                                    </li>

                                </ul>
                            </li>
                            {/* <li className="cl4">
                                <ul className="brr">

                                    <li>
                                        <a href="privacy-policy">
                                            <img src={require("./../assets/images/privacy.png")} className="imggg" alt="" />
                                            privacy policy
                                        </a>
                                    </li>

                                </ul>
                            </li> */}
                            {/* <li className="cl5">
                            <ul className="brr" style="border-right: unset !important;">
                            </ul>
                        </li> */}
                        </ul>
                    </div>
                </div>
            </section>
            <RightModalComponent
                title={'Profile'}
                show={showSideBar}
                handleModal={handleSideBar}
                width={'25%'}
            >
                <UserSidebar handleModal={handleSideBar} showbuttons={true} />
            </RightModalComponent>

            <RightModalComponent
                title={'Notification'}
                show={showNotiBar}
                handleModal={handleNotiBar}
                width={'25%'}
            >
                {/* <UserSidebar handleModal={handleSideBar} showbuttons={true} /> */}
                <NotificationSidebar />
            </RightModalComponent>
        </header>
    )
} 